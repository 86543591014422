import { render, staticRenderFns } from "./CC.vue?vue&type=template&id=2e411176&scoped=true"
import script from "./CC.vue?vue&type=script&lang=js"
export * from "./CC.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/components/tarjeta.css?vue&type=style&index=0&id=2e411176&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e411176",
  null
  
)

export default component.exports