<template>
  <div class="app-popup-container">
    <div class="app-popup-mask"></div>
    <div class="recorrido">
      <label class="recorrido-title">Realicemos un primer recorrido</label>
      <img
        class="recorrido-cerrar"
        @click="listo()"
        src="../assets/img/icons/close.png"
      />
      <transition name="fade-fast">
        <div v-show="seccion == 1" class="recorrido-seccion">
          <img
            class="app-mobile-no"
            src="../assets/img/recorrido/herramientas.png"
          />
          <img
            class="app-solo-mobile"
            src="../assets/img/recorrido/herramientas-mobile.png"
          />
          <div>
            <label
              class="recorrido-seccion-title recorrido-seccion-title-herramientas"
              >Herramientas BASF</label
            >
            <label class="recorrido-seccion-text"
              >Podrás ingresar directamente a cada herramienta BASF en un solo
              lugar, sin necesidad de volver a loguearte</label
            >
          </div>
        </div>
      </transition>
      <transition name="fade-fast">
        <div v-show="seccion == 2" class="recorrido-seccion">
          <img
            class="app-mobile-no"
            src="../assets/img/recorrido/botones.png"
          />
          <img
            class="app-solo-mobile"
            src="../assets/img/recorrido/botones-mobile.png"
          />
          <div>
            <label
              class="recorrido-seccion-title recorrido-seccion-title-botones"
              >Botones interactivos</label
            >
            <label class="recorrido-seccion-text"
              >Personaliza tu experiencia BASF, elige la imagen que quieras ver,
              del tamaño que desees verla y controla el estado de tu
              sesión.</label
            >
          </div>
        </div>
      </transition>
      <transition name="fade-fast">
        <div v-show="seccion == 3" class="recorrido-seccion">
          <img
            class="app-mobile-no"
            src="../assets/img/recorrido/resumen.png"
          />
          <img
            class="app-solo-mobile"
            src="../assets/img/recorrido/resumen-mobile.png"
          />
          <div>
            <label
              class="recorrido-seccion-title recorrido-seccion-title-resumen"
              >Tu resumen</label
            >
            <label class="recorrido-seccion-text"
              >Visualiza comunicados e información destacada de tus herramientas
              BASF sin necesidad de ingresar en ellas.</label
            >
          </div>
        </div>
      </transition>
      <transition name="fade-fast">
        <div v-show="seccion == 4" class="recorrido-seccion">
          <img
            class="app-mobile-no"
            src="../assets/img/recorrido/novedades.png"
          />
          <img
            class="app-solo-mobile"
            src="../assets/img/recorrido/novedades-mobile.png"
          />
          <div>
            <label
              class="recorrido-seccion-title recorrido-seccion-title-novedades"
              >Novedades</label
            >
            <label class="recorrido-seccion-text"
              >Entérate de las útimas noticias BASF</label
            >
          </div>
        </div>
      </transition>
      <div class="recorrido-dots">
        <div
          class="recorrido-dot"
          :class="{ 'recorrido-dot-active': seccion >= 1 }"
          @click="seccion = 1"
        ></div>
        <div
          class="recorrido-dot"
          :class="{ 'recorrido-dot-active': seccion >= 2 }"
          @click="seccion = 2"
        ></div>
        <div
          class="recorrido-dot"
          :class="{ 'recorrido-dot-active': seccion >= 3 }"
          @click="seccion = 3"
        ></div>
        <div
          class="recorrido-dot"
          :class="{ 'recorrido-dot-active': seccion == 4 }"
          @click="seccion = 4"
        ></div>
      </div>
      <div class="recorrido-btn-container">
        <button class="recorrido-btn-omitir app-mobile-no" @click="listo()">
          Omitir
        </button>
        <button
          class="recorrido-btn-listo"
          v-show="seccion < 4"
          @click="continuar()"
        >
          Continuar
        </button>
        <button
          class="recorrido-btn-listo"
          v-show="seccion == 4"
          @click="listo()"
        >
          ¡Listo!
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      seccion: 1,
    };
  },
  created() {},
  mounted() {},
  methods: {
    continuar() {
      this.seccion++;
    },
    listo() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/configuracion/listo")
        .then(function () {
          that.$eventHub.$emit("recorrido-listo");
        })
        .catch(function (response) {
          console.log(response);
          // handle error
          if (
            window.location.pathname != "/login" &&
            window.location.pathname.split("/")[1] != "password" &&
            window.location.pathname.split("/")[3] != "password"
          ) {
            that.$router.push("/login");
          }
        });
    },
  },
};
</script>

<style scoped src="../assets/css/components/recorrido.css"></style>
